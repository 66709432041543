import Layout from './App/Pages/Layout';
import { BrowserRouter as Router } from 'react-router-dom';
import TitleList from './App/Pages/Titles/TitleList';
import TokenContext from './App/Contexts/TokenContext';
import useStoredValue from './App/Hooks/UseStoredValue';
import Login from './App/Pages/Login';
import useToken from './App/Hooks/UseToken';

function App() {
  const { token, isValid, setToken, tokenStorageChecked } = useToken();

  return (
    <Router>
      <TokenContext.Provider
        value={{ token, isValid, setToken, tokenStorageChecked }}
      >
        {tokenStorageChecked === true ? (
          token !== undefined && token !== null && isValid === true ? (
            <Layout>
              <TitleList />
            </Layout>
          ) : (
            <Login />
          )
        ) : null}
      </TokenContext.Provider>
    </Router>
  );
}

export default App;
