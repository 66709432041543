import { FormEvent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Alert from './Alert';

type LoginBoxProps = {
  onLogin: (username: string, password: string) => void;
  error?: string;
};

const LoginBox = ({ onLogin, error }: LoginBoxProps) => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const performLogin = (e: FormEvent<HTMLFormElement>) => {
    setErrorMessage(undefined);
    e.preventDefault();

    if (email.length <= 3 && password.length <= 5) {
      setErrorMessage('Invalid username or password');
    } else {
      onLogin(email, password);
    }
  };

  useEffect(() => setErrorMessage(error), [error]);

  return (
    <div className="tw-w-80 tw-m-2 tw-overflow-hidden tw-shadow-md tw-rounded-md tw-bg-white">
      <form onSubmit={performLogin}>
        <div className="tw-flex tw-items-center tw-justify-center tw-bg-blue-400 tw-py-10 tw-px-4">
          <img
            className="tw-place-self-center"
            src="https://portal.chief.gi/assets/images/logo.png"
          />
        </div>
        <div className="tw-bg-gray-200 tw-flex tw-flex-col tw-gap-4 tw-p-4">
          <input
            className="tw-border tw-rounded-md tw-p-2 tw-w-full"
            name="email"
            type="email"
            placeholder="Email Address"
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            className="tw-border tw-rounded-md tw-p-2 tw-w-full"
            name="password"
            type="password"
            placeholder="Password"
            onChange={(e) => setPassword(e.target.value)}
          />

          {errorMessage && <Alert color="red" message={errorMessage} />}
        </div>
        <div className="tw-p-4 tw-flex tw-flex-col tw-gap-2">
          <button
            type="submit"
            className="hover:tw-bg-blue-500 tw-rounded-md tw-uppercase tw-text-white tw-font-extralight tw-bg-blue-400 tw-p-3 tw-text-xl"
          >
            Sign In
          </button>
          <Link
            to="/auth/forgot-password"
            className="tw-text-blue-400 hover:tw-text-blue-500 tw-font-light"
          >
            Forgot Password?
          </Link>
        </div>
      </form>
    </div>
  );
};

export default LoginBox;
