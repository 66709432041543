import React from 'react';

export type PillProps = {
  color: string;
  colorWeight?: number;
  textColor?: string;
  textColorWeight?: number;
  children: any;
};

const Pill = ({
  color,
  colorWeight,
  children,
  textColor,
  textColorWeight,
}: PillProps) => {
  return (
    <div
      className={`tw-py-0.25 tw-text-xs tw-px-3 tw-rounded-sm tw-bg-${[
        color,
        colorWeight,
      ].join('-')} tw-text-${[textColor ?? 'black', textColorWeight]
        .filter((i) => i !== undefined)
        .join('-')} tw-ring-1 tw-ring-${[color, (colorWeight ?? 0) + 100]
        .filter((i) => i !== undefined)
        .join('-')}`}
    >
      {children}
    </div>
  );
};

export default Pill;
