import { useEffect, useState } from 'react';
import useDebounce from '../../Hooks/UseDebounce';

const searchUrl = 'https://api-search-yicvs4tngq-nw.a.run.app/term';

type SearchResult = {
  id: string;
  case_name: string;
  address: string;
  unique_reference: string;
  introducer_reference: string;
  introducer: string;
  di: number;
  stepped_payments: number[];
  created_at: string;
  status: string;
  supervisory_percentage: number;
};

const useClientSearch = (limit: number = 5) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [domainId] = useState<string>('6e29b661-21f8-4649-ae80-f7fd8860d557');
  const [results, setResults] = useState<SearchResult[]>([]);
  const [totalResults, setTotalResults] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const clearSearchResults = (): void => {
    setLoading(false);
    setResults([]);
    setTotalResults(0);
  };

  useEffect(() => {
    if (searchTerm.length < 2) {
      clearSearchResults();
      return;
    }
    clearSearchResults();
    setLoading(true);
    fetch(
      `${searchUrl}?term=${searchTerm}&page=1&limit=${limit}&domainId=${domainId}`,
    )
      .then((results) => results.json())
      .then((results) => {
        setResults(results.data.slice(0, limit));
        setTotalResults(results.meta.totalResults);
      })
      .finally(() => setLoading(false));
  }, [debouncedSearchTerm]);

  return {
    search: setSearchTerm,
    results,
    totalResults,
    loading,
    clearResults: clearSearchResults,
    searchTerm,
  };
};

export default useClientSearch;
