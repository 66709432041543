import React from 'react';

export type TasksWidgetProps = {};

const TasksWidget = (props: TasksWidgetProps) => {
  return (
    <div className="tw-bg-gray-100 tw-flex tw-rounded-full tw-items-center tw-justify-center tw-h-8 tw-w-8 tw-ring-gray-200 tw-ring-1 hover:tw-bg-gray-200 tw-select-none tw-overflow-hidden tw-cursor-pointer">
      <div className="tw-flex-none">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="tw-h-5 tw-w-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
          />
        </svg>
      </div>
    </div>
  );
};

export default TasksWidget;
