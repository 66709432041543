import React, { useRef } from 'react';
import { LinkProps, LinkWrapper } from 'react-inside-angular';
import { Link as RRDLink } from 'react-router-dom';
import useOutsideClick from '../../Hooks/UseOutsideClick';
import useToggle from '../../Hooks/UseToggle';
import Pill from '../Pill';
import useClientSearch from './UseClientSearch';

export type ClientSearchProps = {};

const ClientSearch = ({ Navigate }: LinkProps<ClientSearchProps>) => {
  const {
    results,
    totalResults,
    search,
    loading,
    clearResults,
    searchTerm,
  } = useClientSearch(15);
  const Link: any = Navigate ? LinkWrapper(Navigate).Component : RRDLink;
  const [
    searchResultsVisible,
    toggleSearchResultsVisible,
    setSearchResultsVisible,
  ] = useToggle();
  const dropdownRef: any = useRef();
  const searchBoxRef: any = useRef();

  const clearSearchResults = () => {
    setSearchResultsVisible(false);
    clearResults();
    searchBoxRef.current.value = '';
  };

  useOutsideClick(dropdownRef, clearSearchResults);

  return (
    <div className="tw-relative tw-inline-block tw-flex-1">
      <div className="tw-flex tw-flex-1 tw-justify-center">
        <div className="tw-flex tw-flex-row tw-w-1/2 tw-rounded-full tw-bg-gray-100 tw-ring-1 tw-ring-gray-200 tw-overflow-hidden tw-items-center tw-px-2">
          <div className="flex-none">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="tw-h-5 tw-w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <input
            className="tw-h-7 tw-w-full tw-ml-2 tw-bg-gray-100"
            placeholder="Start typing to search..."
            ref={searchBoxRef}
            onClick={() => setSearchResultsVisible(true)}
            onFocus={() => setSearchResultsVisible(true)}
            onChange={(e) => search(e.target.value)}
          />
        </div>
      </div>

      {searchResultsVisible && (
        <div
          className="tw-w-full tw-flex-col tw-z-50 tw-absolute tw-right-0 tw-bg-white tw-rounded-sm tw-overflow-hidden tw-ring-2 tw-ring-gray-100 tw-shadow-md tw-mt-2"
          ref={dropdownRef}
        >
          {totalResults > 0 ? (
            <>
              <div className="tw-w-full tw-grid tw-grid-cols-3 tw-gap-x-2 tw-gap-y-4 tw-px-2 tw-py-2">
                {results.map((item) => (
                  <Link
                    to={`/cases/${item.id}`}
                    className="tw-px-2 tw-py-1 tw-items-center tw-flex-1 tw-flex tw-flex-col tw-flex-row hover:tw-bg-gray-200 tw-rounded-md"
                    onClick={() => clearSearchResults()}
                  >
                    <div className="tw-font-bold tw-text-base">
                      {item.unique_reference}
                    </div>
                    <div className="tw-font-semibold">{item.case_name}</div>
                    <div className="tw-text-xs tw-italic">{item.address}</div>
                    <div className="tw-py-1">
                      <Pill
                        color={
                          item.status === 'IVA Accepted' ? 'green' : 'gray'
                        }
                        colorWeight={400}
                        textColor="white"
                      >
                        {item.status}
                      </Pill>
                    </div>
                  </Link>
                ))}
              </div>
              <Link
                to={`/cases/search/${searchTerm}`}
                onClick={() => clearSearchResults()}
              >
                <div className="tw-cursor-pointer tw-bg-gray-100 tw-text-center tw-py-1 hover:tw-text-black">
                  View all {totalResults} results
                </div>
              </Link>
            </>
          ) : loading === true ? (
            <div className="tw-bg-blue-100 tw-text-center tw-py-1 tw-italic">
              To search for a client please type the name, postcode or reference
              number in the box above. Results will appear here automatically.
            </div>
          ) : (
            <div className="tw-bg-blue-100 tw-text-center tw-py-1 tw-italic">
              To search for a client please type the name, postcode or reference
              number in the box above. Results will appear here automatically.
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ClientSearch;
