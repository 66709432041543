import { useContext, useState } from 'react';
import LoginBox from '../Components/LoginBox';
import TokenContext from '../Contexts/TokenContext';

type LoginProps = {};

const Login = ({}: LoginProps) => {
  const { setToken } = useContext(TokenContext);

  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined,
  );
  const login = (username: string, password: string) => {
    setErrorMessage(undefined);

    fetch('https://chief-backend-master.herokuapp.com/v1/auth/login', {
      method: 'POST',
      body: JSON.stringify({ username, password }),
      headers: { 'Content-Type': 'application/json' },
    })
      .then((res) => {
        if (res.status !== 200) {
          throw new Error('Invalid username or password');
        }

        return res.json();
      })
      .then((res) => setToken(res.data.token))
      .catch((e) => setErrorMessage(e.message));
  };

  return (
    <div className="tw-flex tw-items-center tw-justify-center tw-w-full tw-h-screen tw-bg-gray-100">
      <LoginBox onLogin={login} error={errorMessage} />
    </div>
  );
};

export default Login;
