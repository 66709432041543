import React, { useContext } from 'react';

import Logo from '../Components/Layout/Logo';
import NavigationBar from '../Components/Layout/NavigationBar';
import NavigationMenu from '../Components/Layout/NavigationMenu';
import TokenContext from '../Contexts/TokenContext';

export type LayoutProps = { children: any };

const Layout = ({ children }: LayoutProps) => {
  const { token } = useContext(TokenContext);

  return (
    <div className="tw-min-h-screen tw-w-full tw-flex tw-flex-col">
      <div
        style={{ height: 78 }}
        className="tw-flex tw-bg-white tw-w-full tw-shadow-sm tw-fixed tw-z-50 tw-t-0"
      >
        <Logo />
        <NavigationBar />
      </div>
      <div className="tw-flex tw-flex-1 tw-height-screen tw-w-full">
        <NavigationMenu />
        <div
          style={{ marginTop: 78, marginLeft: 240 }}
          className="tw-flex-1 tw-p-4"
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;
