import React from 'react';

export type NavigationMenuProps = {};

const NavigationMenu = (props: NavigationMenuProps) => {
  return (
    <div
      style={{ width: 240, marginTop: 78, backgroundColor: '#32323A' }}
      className="tw-h-screen tw-fixed tw-z-50"
    >
      Menu
    </div>
  );
};

export default NavigationMenu;
