import React from 'react';

export type LogoProps = {};

const Logo = (props: LogoProps) => {
  return (
    <div
      style={{ width: 240, backgroundColor: '#4298F5' }}
      className="tw-h-full tw-flex tw-justify-center"
    >
      <img
        className="tw-place-self-center"
        src="https://portal.chief.gi/assets/images/logo.png"
      />
    </div>
  );
};

export default Logo;
