import { createContext } from 'react';

export type TokenState = {
  token: string | undefined;
  isValid: boolean;
  setToken: (token: string | undefined) => void;
  tokenStorageChecked: boolean;
};

const defaultToken: TokenState = {
  token: undefined,
  isValid: false,
  setToken: () => {},
  tokenStorageChecked: false,
};

const TokenContext = createContext<TokenState>(defaultToken);

export default TokenContext;
