import React, { MutableRefObject, useContext, useRef } from 'react';
import { LinkProps, LinkWrapper } from 'react-inside-angular';
import useOutsideClick from '../Hooks/UseOutsideClick';
import useToggle from '../Hooks/UseToggle';
import { Link as RRDLink } from 'react-router-dom';
import TokenContext from '../Contexts/TokenContext';

export type UserMenuProps = {};

const UserMenu = ({ Navigate }: LinkProps<UserMenuProps>) => {
  const { setToken } = useContext(TokenContext);
  const Link: any = Navigate ? LinkWrapper(Navigate).Component : RRDLink;

  const [menuState, toggleMenuState, setMenuState] = useToggle();
  const dropdownRef: any = useRef<HTMLDivElement>();
  useOutsideClick(dropdownRef, () => setMenuState(false));

  return (
    <div
      onClick={() => toggleMenuState()}
      className="tw-relative tw-inline-block"
    >
      <div className="tw-flex tw-items-center tw-bg-gray-100 tw-rounded-full tw-overflow-hidden tw-select-none tw-cursor-pointer tw-ring-gray-200 tw-ring-1 hover:tw-bg-gray-200">
        <div className="tw-rounded-full tw-overflow-hidden">
          <img
            src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50"
            className="tw-w-8 tw-h-8"
          />
        </div>
        <div className="tw-px-2 tw-pr-3">Simon Skinner</div>
      </div>

      {menuState === true && (
        <div
          className="tw-w-40 tw-flex tw-flex-col tw-gap-1 tw-px-2 tw-py-2 tw-z-50 tw-origin-top-right tw-absolute tw-right-0 tw-bg-white tw-rounded-sm tw-ring-2 tw-ring-gray-100 tw-shadow-sm tw-mt-2"
          ref={dropdownRef}
        >
          <Link
            to="/auth/profile"
            className="tw-px-2 tw-py-1 tw-items-center tw-flex-1 tw-flex tw-flex-row hover:tw-bg-gray-200 tw-rounded-md"
          >
            <div className="tw-flex-none tw-mr-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="tw-h-4 tw-w-4"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z"
                  clipRule="evenodd"
                />
                <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z" />
              </svg>
            </div>
            Profile
          </Link>
          <Link
            to="/auth/settings"
            className="tw-px-2 tw-items-center tw-py-1 tw-flex-1 tw-flex hover:tw-bg-gray-200 tw-rounded-md"
          >
            <div className="tw-flex-none tw-mr-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="tw-h-4 tw-w-4"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            Settings
          </Link>
          <div
            onClick={() => setToken(undefined)}
            className="tw-cursor-pointer tw-items-center tw-px-2 tw-py-1 tw-flex-1 tw-flex hover:tw-bg-gray-200 tw-rounded-md"
          >
            <div className="tw-flex-none tw-mr-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="tw-h-4 tw-w-4"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M18 8a6 6 0 01-7.743 5.743L10 14l-1 1-1 1H6v2H2v-4l4.257-4.257A6 6 0 1118 8zm-6-4a1 1 0 100 2 2 2 0 012 2 1 1 0 102 0 4 4 0 00-4-4z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            Log Out
          </div>
        </div>
      )}
    </div>
  );
};

export default UserMenu;
