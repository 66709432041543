import React from 'react';
import ClientSearch from '../ClientSearch';
import TasksWidget from '../TasksWidget';
import UpdateWidget from '../UpdateWidget';
import UserMenu from '../UserMenu';

export type NavigationBarProps = {};

const NavigationBar = (props: NavigationBarProps) => {
  return (
    <div className="tw-flex-1 tw-flex tw-flex-row tw-items-center tw-p-4">
      <div
        style={{ width: 200 }}
        className="tw-flex tw-flex-initial tw-justify-start tw-gap-3"
      >
        <UpdateWidget />
        <TasksWidget />
      </div>

      <div className="tw-flex tw-flex-grow tw-justify-center">
        <ClientSearch />
      </div>

      <div
        style={{ width: 200 }}
        className="tw-flex tw-flex-initial tw-justify-end"
      >
        <UserMenu />
      </div>
    </div>
  );
};

export default NavigationBar;
