import React from 'react';

export type UpdateWidgetProps = {};

const UpdateWidget = (props: UpdateWidgetProps) => {
  return (
    <div className="tw-bg-gray-100 tw-flex tw-rounded-full tw-items-center tw-justify-center tw-h-8 tw-w-8 tw-ring-gray-200 tw-ring-1 hover:tw-bg-gray-200 tw-select-none tw-overflow-hidden tw-cursor-pointer">
      <div className="tw-flex-none">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="tw-h-5 tw-w-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </div>
    </div>
  );
};

export default UpdateWidget;
